import { SET_ACCOUNT_INFO_DETAIL } from "../../../constant/type";

const initialState = {
  accountInfoDetail: null,
};

const accountInfoDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_INFO_DETAIL:
      return {
        ...state,
        accountInfoDetail: action.payload,
      };
    default:
      return state;
  }
};

export default accountInfoDetailReducer;
