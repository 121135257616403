import { SET_VPS_LIST } from "../../../constant/type";
import vpsListService from "../../../services/vps";
import baseService from "../../../services/index";

export const VpsListAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      vpsListService
        .vpsList(params)
        .then((res) => {
          // console.log('Success in vpsListService ==> ', res);
          baseService.setHeader();
          dispatch(actionSetVpsList(res));
          resolve();
        })
        .catch((err) => {
          // console.log('Error in vpsListService ==> ', err);
          dispatch(actionSetVpsList(null));
          reject(err);
        });
    });
  };
};

export const actionSetVpsList = (payload) => {
  return {
    type: SET_VPS_LIST,
    payload: payload,
  };
};
