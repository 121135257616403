import {
  SET_DASHBOARD_V1_DAILY,
  SET_DASHBOARD_V1_MONTHLY,
  SET_DASHBOARD_V1_YEARLY,
  SET_DASHBOARD_V1_EQUITY_BALANCE,
  SET_DASHBOARD_V1_DAILY_MEMBER,
  SET_DASHBOARD_V1_YEARLY_MEMBER,
  SET_DASHBOARD_V1_MONTHLY_MEMBER,
  SET_DASHBOARD_TOTAL_PROFIT,
  SET_DASHBOARD_TOTAL_WITHDRAWAL,
  SET_DASHBOARD_TOTAL_DEPOSIT,
} from "../../../constant/type";
import dashboardService from "../../../services/dashboard/index";
import baseService from "../../../services/index";

// const fileName = "[REDUX ACTION DASHBOARD] ";

/* Example Params:
  {
      "agent_id":"9d3a505b-0d22-4d2c-a878-bc8a913b97bc"
  }
*/
/* THIS DISPATCH IS USED FOR DASHBOARD V1 */
export const DashboardVpsV1Daily = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardDailyProfitAgent(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardV1Daily(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardV1Daily(null));
          reject(err);
        });
    });
  };
};

export const DashboardVpsV1Monthly = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardMontlyProfitAgent(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardV1Monthly(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardV1Monthly(null));
          reject(err);
        });
    });
  };
};

export const DashboardVpsV1Yearly = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardYearlyProfitAgent(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardV1Yearly(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardV1Yearly(null));
          reject(err);
        });
    });
  };
};

export const DashboardVpsV1EquityBalance = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardEquityBalanceAgent(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardV1EquityBalance(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardV1EquityBalance(null));
          reject(err);
        });
    });
  };
};

// -----MEMBER-----
export const DashboardVpsV1DailyMember = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardDailyProfitMember(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 Member ==> ", res);
          dispatch(actionDashboardV1DailyMember(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 Member ==> ", err);
          dispatch(actionDashboardV1DailyMember(null));
          reject(err);
        });
    });
  };
};

export const DashboardVpsV1MonthlyMember = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardMontlyProfitMember(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 Member ==> ", res);
          dispatch(actionDashboardV1MonthlyMember(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 Member ==> ", err);
          dispatch(actionDashboardV1MonthlyMember(null));
          reject(err);
        });
    });
  };
};

export const DashboardVpsV1YearlyMember = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardYearlyProfitMember(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 Member ==> ", res);
          dispatch(actionDashboardV1YearlyMember(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 Member ==> ", err);
          dispatch(actionDashboardV1YearlyMember(null));
          reject(err);
        });
    });
  };
};

export const DashboardTotalProfit = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardTotalProfit(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardTotalProfit(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardTotalProfit(null));
          reject(err);
        });
    });
  };
};

export const DashboardTotalWithdrawal = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardTotalWithdrawal(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardTotalWithdrawal(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardTotalWithdrawal(null));
          reject(err);
        });
    });
  };
};

export const DashboardTotalDeposit = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dashboardService
        .dashboardTotalDeposit(params)
        .then((res) => {
          baseService.setHeader();
          // console.log(fileName, "response service dashboard V1 ==> ", res);
          dispatch(actionDashboardTotalDeposit(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "error service dashboard V1 ==> ", err);
          dispatch(actionDashboardTotalDeposit(null));
          reject(err);
        });
    });
  };
};

/* THIS ACTION TO REDUCERS FOR DASHBOARD V1 */
export const actionDashboardV1Daily = (payload) => {
  return {
    type: SET_DASHBOARD_V1_DAILY,
    payload: payload,
  };
};

export const actionDashboardV1Monthly = (payload) => {
  return {
    type: SET_DASHBOARD_V1_MONTHLY,
    payload: payload,
  };
};

export const actionDashboardV1Yearly = (payload) => {
  return {
    type: SET_DASHBOARD_V1_YEARLY,
    payload: payload,
  };
};

export const actionDashboardV1EquityBalance = (payload) => {
  return {
    type: SET_DASHBOARD_V1_EQUITY_BALANCE,
    payload: payload,
  };
};

export const actionDashboardTotalProfit = (payload) => {
  return {
    type: SET_DASHBOARD_TOTAL_PROFIT,
    payload: payload,
  };
};

export const actionDashboardTotalWithdrawal = (payload) => {
  return {
    type: SET_DASHBOARD_TOTAL_WITHDRAWAL,
    payload: payload,
  };
};

export const actionDashboardTotalDeposit = (payload) => {
  return {
    type: SET_DASHBOARD_TOTAL_DEPOSIT,
    payload: payload,
  };
};

// -----MEMBER-----

export const actionDashboardV1DailyMember = (payload) => {
  return {
    type: SET_DASHBOARD_V1_DAILY_MEMBER,
    payload: payload,
  };
};
export const actionDashboardV1MonthlyMember = (payload) => {
  return {
    type: SET_DASHBOARD_V1_MONTHLY_MEMBER,
    payload: payload,
  };
};

export const actionDashboardV1YearlyMember = (payload) => {
  return {
    type: SET_DASHBOARD_V1_YEARLY_MEMBER,
    payload: payload,
  };
};
