import { SET_VPS_DETAIL_LIST } from "../../../constant/type";
import vpsListService from "../../../services/vps";
import baseService from "../../../services/index";

export const VpsDetailAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      vpsListService
        .vpsDetailList(params)
        .then((res) => {
          // console.log('Success in vpsListService ==> ', res);
          baseService.setHeader();
          dispatch(actionSetVpsDetail(res));
          resolve();
        })
        .catch((err) => {
          // console.log('Error in vpsListService ==> ', err);
          dispatch(actionSetVpsDetail(null));
          reject(err);
        });
    });
  };
};

export const actionSetVpsDetail = (payload) => {
  return {
    type: SET_VPS_DETAIL_LIST,
    payload: payload,
  };
};
