import React, { createContext, useEffect, useState } from "react";
import mqtt from "mqtt";
// import { useAuth } from '@/store';

export const MqttContext = createContext();

const MQTT_BROKER_URL = "wss://mqtt.3dvam.info";

const MQTT_OPTIONS = {
  protocolVersion: 5,
  username: "papinaga",
  password: "Cideng87(",
  reconnectPeriod: 1000,
  clean: true,
  clientId: `mqtt_${Math.random().toString(16).slice(3)}`,
};

export const MqttProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [messagesTopicTicket, setMessagesTopicTicket] = useState([]);
  const [messagesTopicNotification, setMessagesTopicNotification] = useState(
    []
  );
  // const [messagesTradeChart, setMessagesTradeChart] = useState([]);
  // const [messagesTradeData, setMessagesTradeData] = useState([]);

  const [messagesTradeDataEURUSD, setMessagesTradeDataEURUSD] = useState([]);
  const [messagesTradeChartEURUSD, setMessagesTradeChartEURUSD] = useState([]);

  const [messagesTradeDataBTCUSDc, setMessagesTradeDataBTCUSDc] = useState([]);
  const [messagesTradeChartBTCUSDc, setMessagesTradeChartBTCUSDc] = useState(
    []
  );

  // Initialize MQTT connection on mount
  useEffect(() => {
    const mqttClient = mqtt.connect(MQTT_BROKER_URL, MQTT_OPTIONS);

    mqttClient.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to MQTT broker");
    });

    mqttClient.on("message", (topik, message) => {
      // console.log("🚀 ~ mqttClient.on ~ topic:", topik);
      const parsedMessage = JSON.parse(message.toString());
      // console.log("🚀 ~ mqttClient.on ~ parsedMessage:", parsedMessage);
      if (topik.includes(`ticket`)) {
        setMessagesTopicTicket(parsedMessage);
      } else if (topik.includes(`notification`)) {
        setMessagesTopicNotification(parsedMessage);
      }
      // else if (topik.includes("Tradechart")) {
      //   setMessagesTradeChart(parsedMessage);
      // } else if (topik.includes("Tradedata")) {
      //   setMessagesTradeData(parsedMessage);
      // }
      else if (topik.includes("TradechartV2/EURUSD")) {
        setMessagesTradeChartEURUSD(parsedMessage);
      } else if (topik.includes("TradedataV2/241558524/EURUSD")) {
        setMessagesTradeDataEURUSD(parsedMessage);
      } else if (topik.includes("TradechartV2/BTCUSDc")) {
        setMessagesTradeChartBTCUSDc(parsedMessage);
      } else if (topik.includes("TradedataV2/159018075/BTCUSDc")) {
        setMessagesTradeDataBTCUSDc(parsedMessage);
      }
    });

    mqttClient.on("error", (err) => {
      console.error("Connection error:", err);
      mqttClient.end();
    });

    setClient(mqttClient);

    // Clean up on unmount
    return () => {
      mqttClient.end();
    };
  }, []);

  const subscribeToTopic = (topic) => {
    if (client) {
      client.subscribe(topic, (err) => {
        if (!err) {
          // console.log(`Subscribed to topic: ${topic}`);
        } else {
          console.error(`Subscription error: ${err}`);
        }
      });
    }
  };

  const unsubscribeFromTopic = (topic) => {
    if (client) {
      client.unsubscribe(topic, (err) => {
        if (!err) {
          // console.log(`Unsubscribed from topic: ${topic}`);
        } else {
          console.error(`Unsubscribe error: ${err}`);
        }
      });
    }
  };

  const publishMessage = (topic, message) => {
    if (client) {
      client.publish(topic, message);
    }
  };

  return (
    <MqttContext.Provider
      value={{
        client,
        isConnected,
        messagesTopicNotification,
        messagesTopicTicket,
        // messagesTradeChart,
        // messagesTradeData,
        messagesTradeDataEURUSD,
        messagesTradeChartEURUSD,
        messagesTradeDataBTCUSDc,
        messagesTradeChartBTCUSDc,
        subscribeToTopic,
        publishMessage,
        unsubscribeFromTopic,
      }}
    >
      {children}
    </MqttContext.Provider>
  );
};
