import { SET_INVOICE_LIST } from '../../../constant/type';

const initialState = {
  invoiceList: null,
};

const invoiceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.payload
      };
    default:
      return state;
  }
};

export default invoiceListReducer;
