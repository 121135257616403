import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// import vpsRoutes from "./views/vps/vpsRoutes";
import accountsRoutes from "./views/accounts/accountRoutes";
import invoiceRoutes from "./views/invoice/invoiceRoutes";
// import dailyBalanceRoutes from "./views/dailyBalance/dailyBalanceRoutes";
import dailyProfitRoutes from "./views/dailyProfit/dailyProfitRoutes";
// import agentsRoutes from "./views/agents/agentRoutes";
// import rateRoutes from "./views/rate/rateRoutes";
// import masterSlaveRoutes from "./views/masterSlave/masterSlaveRoutes";
// import settingsRoutes from "./views/settings/settingsRoutes";
import profileRoutes from "./views/profile/profileRoutes";
import tradeChartRoutes from "./views/tradeChart/tradeChartRoutes";
import calendarProfitRoutes from "./views/calender/calenderRoutes";

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);

const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      // ...vpsRoutes,
      ...accountsRoutes,
      // ...dailyBalanceRoutes,
      ...dailyProfitRoutes,
      ...invoiceRoutes,
      // ...agentsRoutes,
      // ...rateRoutes,
      // ...masterSlaveRoutes,
      // ...settingsRoutes,
      ...profileRoutes,
      ...tradeChartRoutes,
      ...calendarProfitRoutes,
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/", element: <Navigate to="session/signin" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
