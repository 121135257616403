import { SET_BALANCE_LIST } from "../../../constant/type";
import balanceListService from "../../../services/balance";
import baseService from "../../../services/index";

// const fileName = "[ACTION BALANCE LIST]";

/* THIS DISPATCH IS USED FOR BALANCE LIST */
export const BalanceListAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      balanceListService
        .balanceList(params)
        .then((res) => {
          // console.log(fileName, "Success in balanceListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetBalanceList(res));
          resolve();
        })
        .catch((err) => {
          // console.log(fileName, "Error in balanceListService ==> ", err);
          dispatch(actionSetBalanceList(null));
          reject(err);
        });
    });
  };
};

/* THIS ACTION TO REDUCERS FOR BALANCE LIST */
export const actionSetBalanceList = (payload) => {
  return {
    type: SET_BALANCE_LIST,
    payload: payload,
  };
};
