import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { MqttProvider } from "./contexts/MQTTContext";

import routes from "./routes";

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <MqttProvider>
          <MatxTheme>
            <CssBaseline />
            {content}
          </MatxTheme>
        </MqttProvider>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
