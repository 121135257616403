import iconLogo from "../../assets/images/logo-3dvam.svg";

const MatxLogo = ({ className }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img alt="icon" src={iconLogo} width={"70%"} />
    </div>
  );
};

export default MatxLogo;
