/* eslint-disable */
import config from "../../../config/index";
import BaseService from "../index";

// const fileName = "[SERVICE ACCOUNT] ";

export default {
  accountList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/accountMt4_byAgent.php", param)
        .then((data) => {
          // console.log(fileName, "response service account ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service account ==> ", data);
          reject(data);
        });
    });
  },

  accountListAll(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/accountMt4_list.php", param)
        .then((data) => {
          // console.log(fileName, "response service account list ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service account list ==> ", data);
          reject(data);
        });
    });
  },

  accountInfoList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/accountMt4_member.php", param)
        .then((data) => {
          // console.log(fileName, "response service account list ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service account list ==> ", data);
          reject(data);
        });
    });
  },

  accountDetail(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/accountMt4_member_detail.php", param)
        .then((data) => {
          // console.log(fileName, "response service account list ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service account list ==> ", data);
          reject(data);
        });
    });
  },

  accountTakeProfitList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/accountMt4_take_profit.php", param)
        .then((data) => {
          // console.log(fileName, "response service account ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service account ==> ", data);
          reject(data);
        });
    });
  },
};
