import {
  SET_PROFIT_ACCOUNT_LIST,
  SET_PROFIT_DATE_BYAGENT_LIST,
  SET_PROFIT_LIST,
  SET_PROFIT_ALL_LIST,
  SET_PROFIT_DATE_ALL_LIST,
  SET_HISTORY_PROFIT_ACCOUNT_LIST,
} from "../../../constant/type";

const initialState = {
  profitList: null,
  profitAllList: null,
  profitAccountList: null,
  profitDateByAgentList: null,
  profitDateAll: null,
};

const profitListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFIT_LIST:
      return {
        ...state,
        profitList: action.payload,
      };
    case SET_PROFIT_ALL_LIST:
      return {
        ...state,
        profitAllList: action.payload,
      };
    case SET_PROFIT_ACCOUNT_LIST:
      return {
        ...state,
        profitAccountList: action.payload,
      };
    case SET_HISTORY_PROFIT_ACCOUNT_LIST:
      return {
        ...state,
        profitHistoryAccountList: action.payload,
      };
    case SET_PROFIT_DATE_BYAGENT_LIST:
      return {
        ...state,
        profitDateByAgentList: action.payload,
      };
    case SET_PROFIT_DATE_ALL_LIST:
      return {
        ...state,
        profitDateAll: action.payload,
      };
    default:
      return state;
  }
};

export default profitListReducer;
