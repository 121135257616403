import { lazy } from "react";
import Loadable from "app/components/Loadable";


const InvoiceList = Loadable(lazy(() => import("./invoiceList")));
const InvoiceDetail = Loadable(lazy(() => import("./invoiceDetail")));
const invoiceRoutes = [
  { path: "/invoice/list", element: <InvoiceList /> },
  { path: "/invoice/detail/:id", element: <InvoiceDetail /> }
];

export default invoiceRoutes;
