/* eslint-disable */
import config from "../../../config/index";
import BaseService from "../index";

const fileName = "[SERVICE DASHBOARD] ";

export default {
  // ===================== Dashboard v1 =====================
  dashboardDailyProfitAgent(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_daily_profit_agent.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard daily profit by agent ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard daily profit by agent ==> ",
            error
          );
          reject(error);
        });
    });
  },

  dashboardMontlyProfitAgent(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_monthly_profit_agent.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard monthly profit agent ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard monthly profit agent ==> ",
            error
          );
          reject(error);
        });
    });
  },
  dashboardYearlyProfitAgent(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_yearly_profit_agent.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard lifetime profit agent ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard lifetime profit agent ==> ",
            error
          );
          reject(error);
        });
    });
  },
  dashboardEquityBalanceAgent(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_daily_equity_agent.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard equity & balance agent ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard equity & balance agent ==> ",
            error
          );
          reject(error);
        });
    });
  },

  // -----MEMBER-----
  dashboardDailyProfitMember(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_daily_profit_member.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard daily profit by member ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard daily profit by member ==> ",
            error
          );
          reject(error);
        });
    });
  },

  dashboardMontlyProfitMember(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_monthly_profit_member.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard monthly profit member ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard monthly profit member ==> ",
            error
          );
          reject(error);
        });
    });
  },

  dashboardYearlyProfitMember(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_yearly_profit_member.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard lifetime profit member ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(
            fileName,
            "error dashboard lifetime profit member ==> ",
            error
          );
          reject(error);
        });
    });
  },

  dashboardTotalProfit(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_total_profit.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard total profit ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(fileName, "error dashboard total profit ==> ", error);
          reject(error);
        });
    });
  },

  dashboardTotalWithdrawal(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_total_withdrawal.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard total withdrawal ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(fileName, "error dashboard total withdrawal ==> ", error);
          reject(error);
        });
    });
  },

  dashboardTotalDeposit(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard_total_deposit.php", param)
        .then((data) => {
          // console.log(
          //   fileName,
          //   "data dashboard total deposit ==> ",
          //   data
          // );
          resolve(data);
        })
        .catch((error) => {
          console.log(fileName, "error dashboard total deposit ==> ", error);
          reject(error);
        });
    });
  },

  // ===================== Dashboard v2 =====================
  dialyProfitList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/daily_profit", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
  monthlyProfitList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/monthly_profit", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
  yearlyProfitList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/yearly_profit", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
  dialyProfitAgentList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/daily_profit_agent", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
  monthlyProfitAgentList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/monthly_profit_agent", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
  yearlyProfitAgentList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/dashboard/yearly_profit_agent", param)
        .then((data) => {
          // console.log(fileName, "data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log(fileName, "data ==> ", data.message);
          reject(data);
        });
    });
  },
};
