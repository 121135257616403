import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import vpsListReducer from "./reducers/vps/vpsList";
import vpsDetailReducer from "./reducers/vps/vpsDetail";
import accountListReducer from "./reducers/account/accountlist";
import balanceListReducer from "./reducers/balance/balanceList";
import profitListReducer from "./reducers/profit/profitList";
import invoiceListReducer from "./reducers/invoice";
import dashboardVpsV1Reducer from "./reducers/dashboard/dashboardVpsV1";
import accountListAllReducer from "./reducers/account/accountListAll";
import accountTakeProfitListReducer from "./reducers/account/accountTakeProfitlist";
import accountInfoListReducer from "./reducers/account/accountInfoList";
import accountInfoDetailReducer from "./reducers/account/accountDetail";
import tradeReducer from "./reducers/trade/trade";
import PercentageProfitReducer from "./reducers/percentage_profit/percentage_profit";

const rootReducer = combineReducers({
  auth: authReducer,
  userProfile: userReducer,
  vpsList: vpsListReducer,
  vpsDetail: vpsDetailReducer,
  accountList: accountListReducer,
  accountTakeProfitList: accountTakeProfitListReducer,
  accountInfoList: accountInfoListReducer,
  accountInfoDetail: accountInfoDetailReducer,
  accountListAll: accountListAllReducer,
  balanceList: balanceListReducer,
  profitList: profitListReducer,
  invoiceList: invoiceListReducer,
  dashboardV1: dashboardVpsV1Reducer,
  trade: tradeReducer,
  percentageProfit: PercentageProfitReducer,
});

const persistConfig = {
  key: "root",
  storage,
  // Anda juga dapat menambahkan whitelist untuk reducer mana yang ingin Anda persist
  whitelist: [
    "auth",
    "userProfile",
    "vpsList",
    "vpsDetail",
    "accountList",
    "balanceList",
    "profitList",
    "dashboardV1",
    "accountListAll",
    "accountInfoList",
    "accountInfoDetail",
    "trade",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
const persistor = persistStore(store);

export { store, persistor };
