import { SET_ACCOUNT_LIST_ALL } from "../../../constant/type";

const initialState = {
  accountListAll: null,
};

const accountListAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_LIST_ALL:
      return {
        ...state,
        accountListAll: action.payload,
      };
    default:
      return state;
  }
};

export default accountListAllReducer;
