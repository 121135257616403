import { lazy } from "react";
import Loadable from "app/components/Loadable";

const DailyProfitList = Loadable(lazy(() => import("./dailyProfitList")));

const dailyProfitRoutes = [
  { path: "/daily-profit/list", element: <DailyProfitList /> },
];

export default dailyProfitRoutes;
