import {
  SET_PERCENTAGE_PROFIT_AGENT,
  SET_PERCENTAGE_PROFIT_MEMBER,
} from "../../../constant/type";

const initialState = {
  percentageProfit: null,
};

const PercentageProfitReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERCENTAGE_PROFIT_AGENT:
      return {
        ...state,
        percentageProfitAgent: action.payload,
      };
    case SET_PERCENTAGE_PROFIT_MEMBER:
      return {
        ...state,
        percentageProfitMember: action.payload,
      };
    default:
      return state;
  }
};

export default PercentageProfitReducer;
