import { SET_VPS_LIST } from '../../../constant/type';

const initialState = {
  vpsList: null
};

const vpsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VPS_LIST:
      return {
        ...state,
        vpsList: action.payload
      };
    default:
      return state;
  }
};

export default vpsListReducer;
