import { SET_BALANCE_LIST } from '../../../constant/type';

const initialState = {
  balanceList: null
};

const balanceListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BALANCE_LIST:
      return {
        ...state,
        balanceList: action.payload
      };
    default:
      return state;
  }
};

export default balanceListReducer;
