import {
  SET_TAKE_PROFIT,
  SET_TRADE,
  SET_TRADING_VIEW,
} from "../../../constant/type";

const initialState = {
  tradeChart: null,
  takeProfit: null,
  tradingView: null,
};

const tradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAKE_PROFIT:
      return {
        ...state,
        takeProfit: action.payload,
      };
    case SET_TRADE:
      return {
        ...state,
        tradeChart: action.payload,
      };
    case SET_TRADING_VIEW:
      return {
        ...state,
        tradingView: action.payload,
      };
    default:
      return state;
  }
};

export default tradeReducer;
