/* eslint-disable */
import config from "../../../config/index";
import BaseService from "../index";

export default {
  profitList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_profit_byAgent.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },
  profitAccountList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_profit_account.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },
  profitHistoryAccountList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_account_profit_history.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },
  profitDateByAgentList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_profit_date_byAgent.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },

  profitAllList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_profit_member.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },

  profitDateAllList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_profit_date.php", param)
        .then((data) => {
          // console.log("data ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          console.log("data ==> ", data.message);
          reject(data);
        });
    });
  },
};
