/* eslint-disable */
import axios from "axios";
import tokenService from "../token/index";

const fileName = "[SERVICE AXIOS] ";

const loginConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export default {
  errorHandler(error) {
    if (!error.response) {
      // NETWORK ERROR
      return Promise.reject(
        new Error("Network Error: Unable to connect to the server.")
      );
    } else if (error.response.status === 401 || error.response.status === 403) {
      // AUTH ERROR
      console.error("Authentication error:", error.response.data.error);
      return Promise.reject(error.response.data.error);
    } else {
      // OTHER ERROR
      console.error("API error:", error.response);
      return Promise.reject(error.response);
    }
  },
  successHandler(response) {
    // LOGIN REQuEST
    if (!response.status === 200) return Promise.resolve(response.data);
    // OTHER REQUEST
    if (response.status === 200) return Promise.resolve(response.data);
    return Promise.reject(response?.data?.message || "");
  },
  setHeader() {
    let tokenObject = tokenService.getToken();
    if (!tokenObject) {
      // this.$store.dispatch(LOGOUT);
    } else {
      axios.defaults.headers.common["Authorization"] = "bearer " + tokenObject;
    }
  },
  removeHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },
  init(apiUrl) {
    if (axios.defaults.baseURL !== apiUrl) {
      axios.defaults.baseURL = apiUrl;

      // Add a request interceptor to log the request URL
      axios.interceptors.request.use((request) => {
        // console.log(
        //   '\x1b[37m\x1b[44m\x1b[1m%s\x1b[0m',
        //   fileName +
        //   "Starting [" + request?.method + "] Request URL ==>\n",
        //   request?.baseURL + request?.url
        // );
        // console.log(
        //   '\x1b[37m\x1b[44m\x1b[1m%s\x1b[0m',
        //   fileName +
        //   "Starting [" + request?.method + "] Request DATA ==>\n",
        //   request?.data
        // );
        return request;
      });

      // Add a response interceptor to log the response
      axios.interceptors.response.use(
        (response) => {
          // console.log(
          //   "\x1b[42m\x1b[30m\x1b[1m%s\x1b[0m",
          //   fileName +
          //     "Finish Get Status [" +
          //     response?.status +
          //     "] Response ==>\n",
          //   response?.data
          // );
          return this.successHandler(response);
        },
        (error) => {
          console.log(
            "\x1b[41;37;1m%s\x1b[0m",
            fileName + "Finish Get Status Error ==>\n",
            error
          );
          return this.errorHandler(error);
        }
      );

      if (tokenService.getToken()) this.setHeader();
    }
  },
  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  },
  get(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.get(resource);
  },
  post(resource, params, query = null) {
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params);
  },
  put(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.put(resource, params);
  },
  download(resource, params, config, query) {
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params, config);
  },
  delete(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.delete(resource);
  },
  login(username, password) {
    var payload =
      "username=" + username + "&password=" + password + "&grant_type=password";
    return axios.post("token", payload, loginConfig);
  },
};
