import {
  SET_DASHBOARD_V1_DAILY,
  SET_DASHBOARD_V1_MONTHLY,
  SET_DASHBOARD_V1_YEARLY,
  SET_DASHBOARD_V1_EQUITY_BALANCE,
  SET_DASHBOARD_V1_DAILY_MEMBER,
  SET_DASHBOARD_V1_MONTHLY_MEMBER,
  SET_DASHBOARD_V1_YEARLY_MEMBER,
  SET_DASHBOARD_TOTAL_PROFIT,
  SET_DASHBOARD_TOTAL_WITHDRAWAL,
  SET_DASHBOARD_TOTAL_DEPOSIT,
} from "../../../constant/type";

const initialState = {
  dashboardV2Daily: null,
  dashboardV2DailyMember: null,
  dashboardV2MontlyMember: null,
  dashboardV2YearlyMember: null,
  dashboardV2Montly: null,
  dashboardV2Yearly: null,
  dashboardV2EquityBalance: null,
};

const dashboardVpsV1Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_V1_DAILY:
      return {
        ...state,
        dashboardV2Daily: action.payload,
      };
    case SET_DASHBOARD_V1_MONTHLY:
      return {
        ...state,
        dashboardV2Montly: action.payload,
      };
    case SET_DASHBOARD_V1_YEARLY:
      return {
        ...state,
        dashboardV2Yearly: action.payload,
      };
    case SET_DASHBOARD_V1_EQUITY_BALANCE:
      return {
        ...state,
        dashboardV2EquityBalance: action.payload,
      };
    case SET_DASHBOARD_V1_DAILY_MEMBER:
      return {
        ...state,
        dashboardV2DailyMember: action.payload,
      };
    case SET_DASHBOARD_V1_MONTHLY_MEMBER:
      return {
        ...state,
        dashboardV2MontlyMember: action.payload,
      };
    case SET_DASHBOARD_V1_YEARLY_MEMBER:
      return {
        ...state,
        dashboardV2YearlyMember: action.payload,
      };
    case SET_DASHBOARD_TOTAL_PROFIT:
      return {
        ...state,
        dashboardTotalProfit: action.payload,
      };
    case SET_DASHBOARD_TOTAL_WITHDRAWAL:
      return {
        ...state,
        dashboardTotalWithdrawal: action.payload,
      };
    case SET_DASHBOARD_TOTAL_DEPOSIT:
      return {
        ...state,
        dashboardTotalDeposit: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardVpsV1Reducer;
