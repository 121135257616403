/* eslint-disable */
import config from "./../../../config/index";
import BaseService from "../index";

// const fileName = "[SERVICE AUTH] ";

export default {
  login(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("/loginInfo.php", param)
        .then((data) => {
          // console.log(fileName, "response service auth login ==> ", data);
          resolve(data);
        })
        .catch((data) => {
          // console.log(fileName, "error service auth login ==> ", data);
          reject(data);
        });
    });
  },
};
