/* eslint-disable */
const TOKEN_KEY = '3dvam.key';

export const saveToken = (token) => {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () => {
  try {
    let tokenObject = localStorage.getItem(TOKEN_KEY);
    if (tokenObject) tokenObject = JSON.parse(tokenObject);
    else tokenObject = null;
    return tokenObject;
  } catch (error) {
    destroyToken();
    return null;
  }
};

export default {
  getToken,
  saveToken,
  destroyToken
};
