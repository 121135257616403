import { lazy } from "react";
import Loadable from "app/components/Loadable";

const AccountList = Loadable(lazy(() => import("./accountList")));
const AccountDetail = Loadable(lazy(() => import("./accountDetail")));

const accountsRoutes = [
    { path: "/accounts/list", element: <AccountList /> },
    { path: "/accounts/detail/:id", element: <AccountDetail /> }
];

export default accountsRoutes;
