import {
  SET_PROFIT_ACCOUNT_LIST,
  SET_PROFIT_DATE_BYAGENT_LIST,
  SET_PROFIT_LIST,
  SET_PROFIT_ALL_LIST,
  SET_PROFIT_DATE_ALL_LIST,
  SET_HISTORY_PROFIT_ACCOUNT_LIST,
} from "../../../constant/type";
import profitListService from "../../../services/profit";
import baseService from "../../../services/index";

export const ProfitListAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitList(params)
        .then((res) => {
          // console.log("Success in profitListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetProfitList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitListService ==> ", err);
          dispatch(actionSetProfitList(null));
          reject(err);
        });
    });
  };
};

export const ProfitAllListAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitAllList(params)
        .then((res) => {
          // console.log("Success in profitAllListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetProfitAllList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitAllListService ==> ", err);
          dispatch(actionSetProfitAllList(null));
          reject(err);
        });
    });
  };
};

export const ProfitDateAllAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitDateAllList(params)
        .then((res) => {
          // console.log("Success in profitDateAllListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetProfitDateAllList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitDateByAllListService ==> ", err);
          dispatch(actionSetProfitDateAllList(null));
          reject(err);
        });
    });
  };
};

export const ProfitAccountAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitAccountList(params)
        .then((res) => {
          // console.log("Success in profitAccountListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetProfitAccountList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitAccountListService ==> ", err);
          dispatch(actionSetProfitAccountList(null));
          reject(err);
        });
    });
  };
};

export const HistoryProfitAccountAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitHistoryAccountList(params)
        .then((res) => {
          // console.log("Success in profitHistoryAccountListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetHistoryProfitAccountList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitHistoryAccountListService ==> ", err);
          dispatch(actionSetHistoryProfitAccountList(null));
          reject(err);
        });
    });
  };
};

export const ProfitDateByAgentAction = (params) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      profitListService
        .profitDateByAgentList(params)
        .then((res) => {
          // console.log("Success in profitDateByAgentListService ==> ", res);
          baseService.setHeader();
          dispatch(actionSetProfitDateByAgentList(res));
          resolve();
        })
        .catch((err) => {
          // console.log("Error in profitDateByAgentListService ==> ", err);
          dispatch(actionSetProfitDateByAgentList(null));
          reject(err);
        });
    });
  };
};

export const actionSetProfitList = (payload) => {
  return {
    type: SET_PROFIT_LIST,
    payload: payload,
  };
};

export const actionSetProfitAllList = (payload) => {
  return {
    type: SET_PROFIT_ALL_LIST,
    payload: payload,
  };
};

export const actionSetProfitAccountList = (payload) => {
  return {
    type: SET_PROFIT_ACCOUNT_LIST,
    payload: payload,
  };
};

export const actionSetHistoryProfitAccountList = (payload) => {
  return {
    type: SET_HISTORY_PROFIT_ACCOUNT_LIST,
    payload: payload,
  };
};

export const actionSetProfitDateByAgentList = (payload) => {
  return {
    type: SET_PROFIT_DATE_BYAGENT_LIST,
    payload: payload,
  };
};

export const actionSetProfitDateAllList = (payload) => {
  return {
    type: SET_PROFIT_DATE_ALL_LIST,
    payload: payload,
  };
};
