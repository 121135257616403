/* eslint-disable */
import config from "../../../config/index";
import BaseService from "../index";

export default {
  balanceList(param) {
    return new Promise((resolve, reject) => {
      BaseService.init(config.API_BASE_URL);
      BaseService.post("daily_balance_byAgent.php", param)
        .then((data) => {
          // console.log('data ==> ', data);
          resolve(data);
        })
        .catch((data) => {
          // console.log('data ==> ', data.message);
          reject(data);
        });
    });
  },
};
