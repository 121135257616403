import { SET_TAKE_PROFIT_ACCOUNT_LIST } from '../../../constant/type';

const initialState = {
  accountTakeProfitList: null
};

const accountTakeProfitListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAKE_PROFIT_ACCOUNT_LIST:
      return {
        ...state,
        accountTakeProfitList: action.payload
      };
    default:
      return state;
  }
};

export default accountTakeProfitListReducer;
